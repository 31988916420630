(function(root, factory) {
    if (typeof define === 'function' && define.amd) {
        define(['underscore', 'backbone', 'marionette', 'jquery'], function(_, Backbone, Marionette, $) {
            return (root.App = root.App || factory(root, _, Backbone, Marionette, $));
        });
    } else {
        root.App = factory(root, root._, root.Backbone, root.Marionette, root.jQuery);
    }
}(this, function(root, _, Backbone, Marionette, $) {

    var App = new Marionette.Application();

    // On DOM complete
    $(function () {
        App.addRegions({
            headerRegion: '#header',
            menuRegion: '#menu',
            subNavRegion: '#subNav',
            mainRegion: '#main',
            footerRegion: '#footer'
        });
        App.start();
    });

    App.navigate = function(route,  options){
        route = route || '';
        options || (options = {});
        Backbone.history.navigate(route, options);
    };

    App.getCurrentRoute = function(){
        return $.trim(Backbone.history.fragment);
    };


    App.startSubApp = function(appName, args){
        var currentApp = App.module(appName);
        if (App.currentApp === currentApp){ return; }

        if (App.currentApp){
            App.currentApp.stop();
        }

        App.currentApp = currentApp;
        currentApp.start(args);
    };


    App.on('start', function(){

        App.defaultStartApp = '';

        if (Backbone.history) {
            Backbone.history.start();

            var defaultStartApp = 'newsfeed';

            var availableStartApps = [{
                    name: 'points',
                    start: 'points:planner'
                },
                {
                    name: 'goals/mobile',
                    start: 'health:goals'
                },
                {
                    name: 'goals',//'health',
                    start: 'health:results'
                },
	            {
		            name: 'mango_health_result',
		            start: 'health:results'
	            },
                {
                    name: 'yourAccount',
                    start: 'account:settings'
                },
                {
                    name: 'bytopic',
                    start: 'learning-center:by-topic'
                },
                {
                    name: 'bytype',
                    start: 'learning-center:by-type'
                },
                {
                    name: 'resources',
                    start: 'resources:topics'
                }];

            var currentRoute = this.getCurrentRoute();
            if (currentRoute.indexOf('?') === -1 && currentRoute.indexOf('&') !== -1) { // No options were provided by correct definition way (starting with "?")
                var originalRoute = currentRoute;
                currentRoute = currentRoute.split('&')[0];
                if (originalRoute !== currentRoute) {
                    console.debug('Hash Route has been updated from: "' + originalRoute + '" to: "' + currentRoute + '"');
                    window.location.hash = currentRoute;
                    return;
                }

            }

            var currentStartApp = _.chain(availableStartApps).find(_.bind(function(startApp){
                var isPageApp = document.URL.indexOf('/' + startApp.name) != -1;
                if (isPageApp) {
                    var subModuleTree = startApp.start.split(':').join('/');
                    if (currentRoute) {
                        var isExistedSubModule = currentRoute.indexOf(subModuleTree) != -1;
                        if (isExistedSubModule) {
                            return true;
                        }
                        else {
                            var unknownSubModule = currentRoute.indexOf(startApp.name) == -1;
                            if (unknownSubModule) {
                                return true;
                            }
                            else {
                                return false;
                            }
                        }
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return false;
                }
            }, this)).value();

            var startApp = (! currentStartApp)
                ? defaultStartApp
                : currentStartApp.start;

            App.trigger(startApp);

            App.defaultStartApp = startApp;
        }

        App.isStarted = true;
    });

    App.on('app:global:height-modification', function () {
        $(window).trigger('globalHeight:changed');
    });

    // >>> EventBroker

    App.EventBroker = Marionette.Controller.extend({
        stack: {},
        Model: Backbone.Model.extend({
            idAttribute: '_generatedID'
        }),
        Collection: Backbone.Collection.extend({}),
        initialize: function(){},
        initializeCollector: function(id){
            if (! (id in this.stack)){
                this.stack[id] = {aggregated: new this.Collection()};
            }
        },
        put: function(id, data){
            if (id === undefined){
                return false;
            }

            this.initializeCollector(id);

            return this.stack[id].aggregated.add(new this.Model(_.extend({_generatedID: $.fn.generateID()}, data)));
        },
        take: function(id){
            this.initializeCollector(id);
            return this.stack[id].aggregated;
        }
    });

    var eventBroker = new App.EventBroker();

    App.reqres.setHandler('broker', function(){
        return eventBroker;
    });

    // EventBroker <<<

    return App;
}));